body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6); /* Light background with transparency */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.fallback-container {
  display: flex;
  flex-direction: column; /* Align logo and text vertically */
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #7e66bc,#5b298c); /* Gradient background */
  color: #fff; /* Text color */
}

/* Animated logo container */
.animated-logo {
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%; /* Circular container */
  animation: pulse 2s infinite; /* Adds pulsing effect */
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.4), 0 0 40px rgba(255, 255, 255, 0.2); /* Glowing effect */
}

/* Logo image styling */
.animated-logo img {
  width: 80%;
  height: 80%;
  object-fit: contain;
  border-radius: 50%; /* Ensures the logo remains circular */
  animation: spin 2s linear infinite; /* Rotating animation */
}

/* Loading text styling */
.loading-text {
  margin-top: 20px;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1.2px;
  animation: fade 2s infinite;
}

/* Spin animation for the logo */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Pulse animation for the container */
@keyframes pulse {
  0%, 100% {
    transform: scale(1);
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.4), 0 0 40px rgba(255, 255, 255, 0.2);
  }
  50% {
    transform: scale(1.1);
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.3);
  }
}

/* Fade animation for the loading text */
@keyframes fade {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
